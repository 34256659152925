.container {
	display: block;
	border-bottom: 1px solid #ddd;
	padding-bottom: 5px;
	padding-top: 5px;
}

.containerCenter {
	text-align: center;
}

.image {
	display: block;
	padding-top: 5px;
}

.imageCenter {
	text-align: center;
	margin: auto;
}

.title {
	font-weight: bold;
}

.list {
	list-style: none;
	padding-left: 0px;
	margin: 0px;
	width: 234px;
	max-width: 234px;
}

.listItem {
	margin: 0px;
}
.legendLabel {
	display: inline-block;
	padding-left: 5px;
	max-width: 200px;
	white-space: nowrap;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: Verdana, Arial, sans-serif !important;
	font-size: 9pt;
	vertical-align: middle;
}
