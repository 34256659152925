.sc-toc-item-layer-info-legend-list {
	list-style: none;
	padding-left: 0px;
	margin: 0px;
	width: 234px;
	max-width: 234px;
}

.sc-toc-item-layer-info-legend-list-item {
	margin: 0px;
}
.sc-toc-item-layer-info-legend-list-item .sc-legend-label {
	display: inline-block;
	padding-left: 5px;
	max-width: 200px;
	white-space: nowrap;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: Verdana, Arial, sans-serif !important;
	font-size: 9pt;
	vertical-align: middle;
}
