.map-theme .sc-map-footer-scale {
  /* color: #808080; */
  color: black;
  text-shadow: white 0.1em 0.1em 0.2em;
  padding-top: 3px;
  margin-top: 0px;
  font-size: 8pt;
  text-align: center;
}

.map-theme .sc-map-footer-scale-only {
  font-size: 8pt;
  text-align: center;
  right: 6px;
  bottom: 25px;
  position: absolute;
  width: 100px;
  min-width: 100px !important;
}

.sc-map-footer-scale {
  /* color: #808080; */
  color: black;
  text-shadow: white 0.1em 0.1em 0.2em;
  padding-top: 3px;
  margin-top: 0px;
  font-size: 8pt;
  text-align: center;
}

.sc-map-footer-scale-only {
  bottom: 28px;
}

.sc-map-footer-scale-only > div {
  border: 0px !important;
  padding: 2px;
}

.sc-map-footer-scale-only select {
  border-radius: 4px;
  color: inherit;
  padding: 2px;
  border: transparent;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  font: inherit;
}
.sc-map-footer-scale-only select:focus {
  outline: unset;
  outline-offset: unset;
}
.sc-map-footer-scale-only select:hover {
  cursor: pointer;
}

.map-theme .sc-map-footer-scale-only.topo {
  color: black;
  text-shadow: white 0.1em 0.1em 0.4em;
}

.map-theme .sc-map-footer-scale-only.imagery {
  color: white;
  text-shadow: black 0.1em 0.1em 0.4em;
}

.map-theme .sc-map-footer-scale-only.selector {
  color: black;
  font-size: 12px;
  font-weight: bold;
  text-shadow: white 0.1em 0.1em 0.4em;
  background-color: rgb(72, 105, 155);
  opacity: 0.5;
}

.map-theme .sc-map-footer-tools-button-bar {
  right: 5px;
  bottom: 40px;
  height: 37px;
  width: 190px;
  position: absolute;
  font-size: 10px;
  text-align: center;
  padding: 4px;
}

.map-theme .sc-map-footer-tools-button-bar a {
  color: #fff;
  padding: 4px 5px;
  display: inline-block;
  padding-right: 7px;
  padding-left: 7px;
  border-radius: 5px;
  text-decoration: none;
}

.map-theme .sc-map-footer-tools-button-bar a:hover {
  background-color: #4974a9;
}

.map-theme .sc-map-footer-tools-button-bar-icons {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
  height: 45px;
  display: inline-block;
  cursor: pointer;
}

.map-theme .sc-map-footer-tools-button-bar-icons:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.map-theme .sc-map-footer-tools-button-bar-title {
  font-size: 12px;
  color: #808080;
}

.map-theme .sc-map-footer-tools-button-bar-scale {
  color: #808080;
  padding-top: 3px;
  margin-top: 2px;
}

.map-theme .sc-map-footer-tools-white-label {
  color: #fff !important;
}

.map-theme .sc-map-footer-tools-button-bar-icons {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
  height: 45px;
  display: inline-block;
  cursor: pointer;
}

.map-theme .sc-map-footer-tools-button-bar-icons:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.map-theme .sc-map-footer-tools-button-bar-title {
  font-size: 12px;
  color: #808080;
}

.map-theme .sc-map-footer-tools-button-bar-scale {
  color: #808080;
  padding-top: 3px;
  margin-top: 2px;
}

.map-theme .sc-map-footer-tools-white-label {
  color: #fff !important;
}

#sc-map-footer-tools-help-link {
  display: none;
}


@media only screen and (max-width: 770px) {
  #sc-map-footer-tools-help-link {
    display: inline-block;
  }

  .sc-map-footer-tools-button-bar-title {
    display: none;
  }

  .sc-map-footer-tools-button-bar-scale {
    display: none;
  }

  .sc-map-footer-tools-button-bar-icons {
    height: 31px;
  }

  .sc-map-footer-tools-link-text {
    display: none;
  }

  .sc-map-footer-tools-button-bar {
    bottom: 7px;
    right: 0px;
  }
}

@media only screen and (max-width: 770px) {
  #sc-map-footer-tools-help-link {
    display: inline-block;
  }

  .sc-map-footer-tools-button-bar-title {
    display: none;
  }

  .sc-map-footer-tools-button-bar-scale {
    display: none;
  }

  .sc-map-footer-tools-button-bar-icons {
    height: 31px;
  }

  .sc-map-footer-tools-link-text {
    display: none;
  }

  .sc-map-footer-tools-button-bar {
    bottom: 7px;
    right: 0px;
  }
}
