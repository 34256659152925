.sc-toc-item-layer-label-folder-view {
	display: inline-table;
	max-width: 212px;
}

.sc-toc-item-container-folder-view {
	display: table;
	user-select: none;
	margin-right: 2px;
	margin-left: 2px;
	font-family: Verdana, Arial, sans-serif !important;
	font-size: 9pt;
	width: 315px;
}
.sc-toc-item-container-folder-view.on {
	font-weight: bold;
}
.sc-toc-item-container-folder-view.not-in-scale {
	font-style: italic;
}

.sc-toc-item-container-folder-view.not-in-scale.on {
	color: grey;
}

.sc-toc-item-layer-label {
	display: inline-table;
	position: relative;
	bottom: 2px;
	max-width: 250px;
}

.sc-toc-item-layer-info-live-layer {
	display: inline-table;
	vertical-align: middle;
	margin-left: 5px;
}

.sc-toc-item-plus-minus-container {
	display: inline-table;
	vertical-align: middle;
	cursor: pointer;
}

.sc-toc-item-toolbox {
	position: absolute;
	right: 8px;
	top: 4px;
	cursor: pointer;
}

.sc-toc-item-plus-minus-sign {
	height: 5px;
	width: 10px;
	position: relative;
	top: -11px;
	right: -8px;
	background: url(../images/horizontal_dots.png) 0px no-repeat;
}

.sc-toc-item-layer-name {
	display: inline-table;
	margin-left: 5px;
	width: 290px;
	height: 20px;
	cursor: default;
	height: 100%;
	word-break: break-all;
	vertical-align: text-top;
}

.sc-toc-item-layer-info-container-open-vertical-lines {
	height: 22px;
	width: 11px;
	position: relative;
	top: -11px;
	right: -6px;
	display: table-cell;

	background: url(../images/verticle_dots.png) 0px repeat-y;
}
.sc-toc-item-layer-info-container-open-horizontal-lines {
	height: 2px;
	width: 11px;
	position: relative;
	top: 135px;
	right: 2px;
	background: url(../images/horizontal_dots.png) 0px no-repeat;
}
.sc-toc-item-layer-info-container-open {
	display: table;
	height: auto;
}
.sc-toc-item-layer-info-container-closed {
	display: none;
}

.sc-toc-item-layer-info-legend {
	padding-left: 16px;
}

.sc-toc-item-checkbox {
	display: inline-table;
	margin-left: 5px;
	transform: scale(1.1, 1.1);
}

.sc-toc-layer-info-container {
	display: table;
}

@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.sc-toc-item-checkbox {
			transform: scale(1, 1);
		}
	}
}
