.sc-mymaps-button-bar {
	background: rgb(255, 255, 255);
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(229, 229, 229, 1)));
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
	background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 );
	padding: 4px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.2);
	text-align: center;
	display: table;
	width: 95%;
	margin-bottom: 10px;
	margin-left: 5px;
	margin-top: 5px;
}

.sc-mymaps-button-bar div:first-child {
	/* margin-left: 0px; */
}

.sc-mymaps-button-bar div:last-child {
	/* margin-right: 5px; */
	/* border-right: none; */
}

.sc-mymaps-button-bar button:focus {
	outline: 0;
}
