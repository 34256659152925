#sc-show-window-container {
	position: absolute;
	z-index: 110;
	background-color: #ffffff;
	/* box-shadow: 0 0 17px 2px #848484; */
	left: 381px;
	top: 62px;
	right: 10px;
	bottom: 10px;
	overflow-wrap: break-word;
	border-radius: 5px 5px 0px 0px;
}

#sc-show-window-container.full {
	left: 10px;
}

#sc-show-window-container.overlay {
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	z-index: 100000000;
}
#sc-show-window-container.overlay .sc-show-window-header-popout-button img {
	transform: rotate(180deg);
}

.sc-show-window-container-advanced {
	position: absolute;
	z-index: 110;
	background-color: #ffffff;
	box-shadow: 0 0 17px 2px #848484;
	left: 381px;
	top: 62px;
	right: 10px;
	bottom: 10px;
}

.sc-show-window-header {
	color: #4c4c4c;
	height: 44px;
	border-bottom: 1px solid #ccc;
	border-radius: 5px 5px 0px 0px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: #3980cc; /* Old browsers */
	background: -moz-linear-gradient(top, #3980cc 0%, #2865a2 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3980cc), color-stop(100%, #2865a2)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #3980cc 0%, #2865a2 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #3980cc 0%, #2865a2 100%); /* Opera 11.10+ */
	background: linear-gradient(to bottom, #3980cc 0%, #2865a2 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3980CC', endColorstr='#2865A2',GradientType=0 ); /* IE6-9 */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7abcff', endColorstr='#4096ee',GradientType=0 ); /* IE6-9 */
	color: #fff !important;
	width: 100%;
	display: table;
}

.sc-show-window-header-title {
	padding-top: 14px;
	font-size: 14px;
	padding-left: 7px;
	display: table-cell;
}

.sc-show-window-header-close-button {
	position: absolute;
	right: 12px;
	top: 6px;
	display: table-cell;
}

.sc-show-window-header-popout-button {
	position: absolute;
	right: 56px;
	top: 6px;
	display: table-cell;
}

.sc-show-window-header-button {
	width: 35px !important;
	border: 1px solid #3672b1 !important;
}

.sc-show-window-content {
	padding: 10px;
	bottom: 5px;
	left: 0px;
	position: absolute;
	right: 0px;
	top: 43px;
	border: 1px solid #ccc;
	margin: 7px;
	border-radius: 0px;
	overflow: auto;
}
.sc-show-window-content-no-scroll {
	overflow: hidden;
}

.with-footer {
	bottom: 40px;
}

.sc-show-window-iframe {
	width: 100%;
	height: 100%;
	background-image: none;
	background-position: center center;
	background-repeat: no-repeat;
}

.sc-show-window-footer {
	width: 100%;
	text-align: right;
	position: absolute;
	bottom: 8px;
	margin-left: -7px;
}

#sc-show-window-dont-show-this-again {
	margin-left: 5px !important;
}
@media print {
	#sc-show-window-content {
		display: block;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
