#sc-basic-basemap-main-container {
  left: auto;
  top: 56px;
  right: 7px;
  height: 33px;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  opacity: 0.6;
  border-radius: 4px;
  display: table;
  z-index: 2;
  width: 92px;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
#sc-basic-basemap-main-container:hover {
  opacity: 1;
}

#sc-basic-basemap-options {
  position: absolute;
  left: auto;
  right: -2px;
  top: 2px;
  bottom: auto;
  width: 5px;
  height: 20px;
  padding: 4px;
  cursor: pointer;
  background-image: url(./images/more-options.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.5;
}
#sc-basic-basemap-options:hover {
  opacity: 1;
}

.sc-basic-basemap-collapse-button {
  display: table-cell;
  background-image: url(./images/tab-close.png);
  background-position: center center;
  background-repeat: no-repeat;
  color: #999;
  min-width: 20px !important;
  cursor: pointer;
  border-right: 1px solid #ccc;
}
.sc-basic-basemap-collapse-button.closed {
  background-image: url(./images/tab-open.png);
}

.sc-basic-basemap-arrow {
  width: 100%;
  min-height: unset !important;
  background-image: url(./images/arrow-down.png) !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  position: absolute;
  bottom: 0;
}

.sc-basic-basemap-arrow.open {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.sc-basic-basemap-topo {
  height: 103px;
}
.sc-basic-basemap-topo-container {
  right: 8px;
  width: 92px;
  /* height: 280px; */
  top: 160px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  user-select: none;
  opacity: 0.9;
  font-size: 9pt;
  z-index: 1;
  border: 1px solid #ccc;
}

.sc-basic-basemap-topo-item-container {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  user-select: none;
  font-weight: bold;
}
.sc-basic-basemap-topo-toggle-item-container {
  text-align: center;
  border-bottom: none;
  padding-bottom: 0px;
  user-select: none;
  font-size: 12px;
  min-height: 89px;
  max-height: 89px;
}
.sc-basic-basemap-topo-item-title {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}
.sc-basic-basemap-topo-toggle-item-container img {
  border: none;
}
.sc-basic-basemap-topo-item-container:hover {
  background: linear-gradient(to bottom, rgba(240, 249, 255, 1) 0%, rgb(191, 214, 228) 100%);
  border: 1px solid #90b5d5;
}

.sc-basic-basemap-topo-item-container.active {
  background: linear-gradient(to bottom, rgba(240, 249, 255, 1) 0%, rgb(191, 214, 228) 100%);
  border: 1px solid #90b5d5;
}

.sc-basic-basemap-topo-image {
  width: 70px;
  border: 1px solid #ccc;
  /* margin-left: 4px; */
}

.sc-basic-basemap-streets-label {
  display: block;
  font-size: 10pt;
  margin-left: 11px;
  padding-bottom: 5px;
  padding-top: 7px;
}

.sc-basic-basemap-streets-checkbox {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  position: relative;
  top: -1px;
  *overflow: hidden;
  transform: scale(1.1, 1.1);
}

.sc-basic-basemap-topo-label {
  display: block;
  font-size: 10pt;
  margin-left: 14px;
  padding-bottom: 5px;
  padding-top: 7px;
}

.sc-basic-basemap-topo-checkbox {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  position: relative;
  top: -1px;
  *overflow: hidden;
  transform: scale(1.1, 1.1);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sc-basic-basemap-main-container {
    /* right: 20px; */
  }
  .sc-basic-basemap-imagery-slider-container {
    right: 75px;
  }
}
