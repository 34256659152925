.body,
div,
p {
  font-family: Arial !important;
  /* color: #5b5b5b; */
}
.sc-hidden {
  display: none !important;
}

.sc-container {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: none;
}

.sc-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
  font-size: 14px;
  /*font-family: monospace !important;*/
  /*font-family: Arial;*/
  padding: 5px;
  color: #909097 !important;
  border-radius: none;
  min-height: 32px;
  border: none;
  font-weight: normal;
}

.sc-button:hover {
  border: 1px solid #18A3A0;
  box-shadow: 0px 0px 5px #18A3A0;
}

.sc-button:active {
  border: 1px solid #18A3A0;
}

.sc-button:focus {
  border: 1px solid #18A3A0;
}
/*
.sc-button.active {
    background: rgb(242,245,246); 
    background: -moz-linear-gradient(top, rgba(242,245,246,1) 0%, rgba(227,234,237,1) 37%, rgba(200,215,220,1) 100%); 
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(242,245,246,1)), color-stop(37%,rgba(227,234,237,1)), color-stop(100%,rgba(200,215,220,1))); 
    background: -webkit-linear-gradient(top, rgba(242,245,246,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%); 
    background: -o-linear-gradient(top, rgba(242,245,246,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%); 
    background: linear-gradient(to bottom, rgba(242,245,246,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc',GradientType=0 ); 
    color: #464646;
    border: 1px solid #90b5d5;
    font-weight: bold;
}
*/

.sc-button.active {
  color: #fff !important;
  background: #18A3A0;
  border: 1px solid #18A3A0;
}

.sc-button:disabled {
  color: #bbbbbb;
  opacity: 0.7;
  box-shadow: none;
}

.sc-button > img {
  vertical-align: text-bottom;
}

.sc-button-small {
  min-height: 26px !important;
}

.sc-button-highlight {
  box-shadow: 0 0px 10px #007cff;
}

.sc-button-transparent {
  background: transparent !important;
}

.sc-button-blue {
  background: #3980cc; /* Old browsers */
  background: -moz-linear-gradient(top, #3980cc 0%, #2865a2 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3980cc), color-stop(100%, #2865a2)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #3980cc 0%, #2865a2 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #3980cc 0%, #2865a2 100%); /* Opera 11.10+ */
  background: linear-gradient(to bottom, #3980cc 0%, #2865a2 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3980CC', endColorstr='#2865A2',GradientType=0 ); /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7abcff', endColorstr='#4096ee',GradientType=0 ); /* IE6-9 */
  border: 1px solid #3d7b9e;
  color: #ffffff !important;
}

.sc-button-blue:hover {
  border: 1px solid #1f5c9b;
}

.sc-button-light-blue {
  background: #deefff; /* Old browsers */
  background: -moz-linear-gradient(top, #dceeff 0%, #abd3f5 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #dceeff), color-stop(100%, #abd3f5)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #dceeff 0%, #abd3f5 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #dceeff 0%, #abd3f5 100%); /* Opera 11.10+ */
  background: linear-gradient(to bottom, #dceeff 0%, #abd3f5 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#deefff', endColorstr='#ABD3F5',GradientType=0 ); /* IE6-9 */
  border: 1px solid #88c5ff;
  color: #333333 !important;
}

.sc-button-light-blue:hover {
  border: 1px solid #5db0ff;
}

.sc-button-orange {
  background: #ffc578; /* Old browsers */
  background: -moz-linear-gradient(top, #ffc578 0%, #fb9d23 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffc578), color-stop(100%, #fb9d23)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffc578 0%, #fb9d23 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffc578 0%, #fb9d23 100%); /* Opera 11.10+ */
  background: linear-gradient(to bottom, #ffc578 0%, #fb9d23 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=0 ); /* IE6-9 */
  border: 1px solid #fb870e;
  color: #ffffff !important;
}

.sc-button-orange:hover {
  border: 1px solid #fd8100;
}

.sc-button-green {
  background: #a9db80; /* Old browsers */
  background: -moz-linear-gradient(top, #a9db80 0%, #81ad5d 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a9db80), color-stop(100%, #81ad5d)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #a9db80 0%, #81ad5d 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #a9db80 0%, #81ad5d 100%); /* Opera 11.10+ */
  background: linear-gradient(to bottom, #a9db80 0%, #81ad5d 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a9db80', endColorstr='#81AD5D',GradientType=0 ); /* IE6-9 */
  border: 1px solid #749e6c;
  color: #ffffff !important;
}

.sc-button-green:hover {
  border: 1px solid #38632f;
}

.sc-button-red {
  background: rgb(255, 48, 25); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255, 48, 25, 1) 0%, rgba(207, 4, 4, 1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 48, 25, 1)), color-stop(100%, rgba(207, 4, 4, 1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 48, 25, 1) 0%, rgba(207, 4, 4, 1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 48, 25, 1) 0%, rgba(207, 4, 4, 1) 100%); /* Opera 11.10+ */
  background: linear-gradient(to bottom, rgba(255, 48, 25, 1) 0%, rgba(207, 4, 4, 1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3019', endColorstr='#cf0404',GradientType=0 ); /* IE6-9 */
  border: 1px solid #7d000a;
  color: #ffffff !important;
}

.sc-button-red:hover {
  border: 1px solid #000;
}

.sc-fakeLink {
  color: #337ab7 !important;
  text-decoration: underline;
  cursor: pointer !important;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.sc-info-window-row {
  display: table-row;
  width: 100%;
}

.sc-info-window-label {
  font-weight: bold;
  color: #333333;
  font-size: 11px;
}

.sc-info-window-value {
  color: #484848;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 3px;
  padding-left: 20px;
  background-image: url("./images/arrow_curve.gif");
  background-repeat: no-repeat;
  background-position: left;
  font-size: 11px;
  word-wrap: break-word;
  min-height: 10px;
}
.sc-info-window-label-table {
  display: table-cell;
  font-weight: bold;
  color: #333333;
  font-size: 11px;
  padding: 3px;
  word-wrap: break-word;
  border-color: #f1efeffa !important;
  border: solid;
  border-width: 1px;
  width: 25%;
  text-transform: uppercase;
}

.sc-info-window-value-table {
  display: table-cell;
  color: #484848;
  font-size: 12px;
  word-wrap: break-word;
  padding: 3px;
  width: 75%;
  border: solid;
  border-width: 1px;
  border-color: #f1efeffa;
}

.sc-no-select {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.sc-draw-button-bar {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(229, 229, 229, 1)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 );
  padding: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.sc-draw-button-bar button:first-child {
  margin-left: 0px;
}

.sc-draw-button-bar button {
  height: 40px;
  border: none;
  border-right: 1px solid #e8e8e8;
  background-color: transparent;
  cursor: pointer;
  margin-left: -2px;
  margin-right: -2px;
  border-radius: 4px;
}

.sc-draw-button-bar button.active {
  background: rgb(242, 245, 246); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(242, 245, 246, 1) 0%, rgba(227, 234, 237, 1) 37%, rgba(200, 215, 220, 1) 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(242, 245, 246, 1)),
    color-stop(37%, rgba(227, 234, 237, 1)),
    color-stop(100%, rgba(200, 215, 220, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(242, 245, 246, 1) 0%, rgba(227, 234, 237, 1) 37%, rgba(200, 215, 220, 1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(242, 245, 246, 1) 0%, rgba(227, 234, 237, 1) 37%, rgba(200, 215, 220, 1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(242, 245, 246, 1) 0%, rgba(227, 234, 237, 1) 37%, rgba(200, 215, 220, 1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(242, 245, 246, 1) 0%, rgba(227, 234, 237, 1) 37%, rgba(200, 215, 220, 1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc',GradientType=0 ); /* IE6-9 */
  border: 1px solid rgb(144, 181, 213);
  border: 1px solid rgb(151, 195, 232);
  box-shadow: 0px 0px 5px rgba(136, 231, 255, 0.74);
}

.sc-draw-button-bar button:first-child {
  margin-left: 0px;
}

/*
.sc-draw-button-bar button:last-child {
  
}
*/

.sc-draw-button-bar button:hover {
  background-color: #ededed;
}

.sc-title {
  font-size: 14px;
  color: #797979;
  margin-top: 5px;
  margin-bottom: 7px;
  text-transform: uppercase;
  padding-left: 20px;
  background-image: url("./images/title-icon.png");
  background-repeat: no-repeat;
  background-position-y: center;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.sc-description {
  font-size: 11px;
  color: #808080;
  padding: 5px;
}

.sc-underline {
  border-bottom: 1px solid #e3e3e3;
}

.sc-arrow {
  background-image: url("./images/arrow_curve.gif");
  background-repeat: no-repeat;
}

.sc-glow-container-blue {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 7px #9ecaed;
}

.sc-glow-container-red {
  outline: none;
  border-color: #ff0000;
  box-shadow: 0 0 7px #ff0000;
}

.sc-glow-container-green {
  outline: none;
  border-color: #15ff00;
  box-shadow: 0 0 7px #15ff00;
}

.sc-fieldset {
  padding: 7px;
  padding-top: 0px;
  border: 1px solid #cacaca;
  margin-top: 18px;
}

.sc-fieldset > legend {
  position: relative;
  top: -12px;
  width: 40%;
  border: 1px solid #adadad;
  background-color: #3377be;
  color: #505050;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -5px;
  background: rgb(255, 255, 255); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
}

.sc-fieldset > legend > img {
  vertical-align: text-bottom;
}

.sc-noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.sc-disabled {
  pointer-events: none;
  opacity: 0.4;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sc-border-bottom {
  border-bottom: 1px solid #ddd;
}

.sc-input {
  font-size: 13px;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px;
}

.sc-editable {
  background-image: url(./images/edit-50.png);
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}
.sc-live-layer-popup-content {
  position: relative;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
}

.sc-list-item {
  background-color: #ececec;
  border: 1px solid #d9d9d9;
  padding: 4px;
  font-size: 11px;
  text-align: left;
  margin-bottom: 5px;
  border-radius: 3px;
}

.sc-list-item-container {
  background-color: #fff;
  padding: 7px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
}

.sc-list-item-action-bar {
  padding: 3px;
  text-align: right;
  cursor: pointer;
  border-radius: 2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(229, 229, 229, 1)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#e5e5e5',GradientType=0);
  border: 1px solid #ddd;
  margin-top: 2px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .header,
  .sc-sidebar-root,
  body > div > .map-theme > .map-theme,
  .sc-map-github-button,
  .nav-container,
  .ol-full-screen,
  .sc-map-footer-scale-only,
  #sc-basemap-main-container {
    display: none !important;
  }

  html,
  body,
  #root,
  #root > div,
  #map-root,
  #map-root > .map-theme,
  #map,
  #sc-show-window-container {
    margin: 1% !important;

    width: 98% !important;
    height: 98% !important;
    left: 0px !important;
    right: 0px !important;
    top: 0px !important;
    bottom: 0px !important;
    transform: unset !important;
    page-break-after: none;
  }
}
