.sc-toc-main-container {
	height: 40px;
}

.sc-toc-layer-container {
	max-width: 400px;
	position: absolute;
	top: 148px;
	bottom: 0px;
	width: 100%;
	overflow: visible;
	margin-left: 5px;
}

.sc-toc-footer-button.tools {
	background-position-x: 6px;
	width: 122px;
}
