.map-theme .nav-container {
  top: 61px;
  /* left: 10px; */
  left: 73px;
  height: 37px;
  width: 37px;
  position: absolute;
  z-index: 2;
}
.map-theme .nav-container-slidein {
  /* transform: translateX(-375px);
        transition: transform 400ms ease-in; */
  transform: translateX(0px);
  transition: transform 300ms ease-in;
}
.map-theme .nav-container-slideout {
  /* transform: translateX(0px);
        transition: transform 400ms ease-in; */
  transform: translateX(304px);
  transition: transform 300ms ease-in;
}

.map-theme .zoomButton {
  width: 37px !important;
  height: 37px !important;
  line-height: 34px !important;
  border-radius: 4px !important;
  color: #5d5d5d !important;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  cursor: pointer;
  font-size: 24px;
  user-select: none;
  text-align: center;
  font-family: verdana, helvetica;
  margin-bottom: 5px;
}

.map-theme .fullExtentButton {
  width: 38px;
  height: 38px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  border-radius: 100px;
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  background-position: center center;
}

.map-theme .fullExtentContent {
  width: 38px;
  height: 38px;
  background-image: url(./images/globe-icon.png);
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  border-radius: 100px;
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  background-position: center center;
}

.map-theme .zoomToCurrentLocationButton {
  margin-top: 5px;
  width: 38px;
  height: 38px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  border-radius: 100px;
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  background-position: center center;
}

.map-theme .zoomToCurrentLocationContent {
  width: 38px;
  height: 38px;
  background-image: url(./images/target.png);
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  border-radius: 100px;
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  background-position: center center;
}

.map-theme .extentHistory {
  display: inline-flex;
  width: 37px !important;
  height: 37px !important;
  line-height: 34px !important;
  margin-bottom: 5px;
}
.map-theme .extentHistory .prevExtentButton {
  color: #5d5d5d !important;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  cursor: pointer;
  font-size: 24px;
  user-select: none;
  text-align: center;
  font-family: verdana, helvetica;
  width: 18px !important;
  height: 37px !important;
  border-radius: 4px 0px 0px 4px;
  margin-right: 1px;
}

.map-theme .extentHistory .nextExtentButton {
  color: #5d5d5d !important;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  cursor: pointer;
  font-size: 24px;
  user-select: none;
  text-align: center;
  font-family: verdana, helvetica;
  width: 18px !important;
  height: 37px !important;
  border-radius: 4px;
  border-left: 0px;
  border-radius: 0px 4px 4px 0px;
  margin-left: 1px;
}

.map-theme .extentHistory {
  display: inline-flex;
  width: 37px !important;
  height: 37px !important;
  line-height: 34px !important;
  margin-bottom: 5px;
}
.map-theme .extentHistory .prevExtentButton {
  color: #5d5d5d !important;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  cursor: pointer;
  font-size: 24px;
  user-select: none;
  text-align: center;
  font-family: verdana, helvetica;
  width: 18px !important;
  height: 37px !important;
  border-radius: 4px 0px 0px 4px;
  margin-right: 1px;
}

.map-theme .extentHistory .nextExtentButton {
  color: #5d5d5d !important;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  box-shadow: 0px 0px 5px 0px rgba(80, 80, 80, 0.69);
  cursor: pointer;
  font-size: 24px;
  user-select: none;
  text-align: center;
  font-family: verdana, helvetica;
  width: 18px !important;
  height: 37px !important;
  border-radius: 4px;
  border-left: 0px;
  border-radius: 0px 4px 4px 0px;
  margin-left: 1px;
}

@media only screen and (max-width: 770px) {
  .map-theme .nav-container {
    top: 61px;
    left: 10px;
    height: 37px;
    width: 37px;
    position: absolute;
  }

  .map-theme .nav-container-slideout {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    transform: none !important;
  }

  .map-theme .nav-container-slidein {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    transform: none !important;
  }
}
