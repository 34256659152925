.sc-sidebar-slim-container {
	width: 65px;
	height: 100%;
	position: absolute;
	top: 1px;
	right: -65px;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #252B48;
	padding-bottom: 5px;
	padding-left: 2px;
	padding-right: 1px;
	/*
	background-color: #d7d7d7;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
	*/
}

.sc-sidebar-slim-button-container {
	padding-top: 10px;
	margin: 5px;
	border-radius: 5px;
	border: 1px solid #252;
}
.sc-sidebar-slim-button-container button{
	color: #FFFFFF !important;
}
.sc-sidebar-slim-button-container.active {
	background-color: #18A3A0;
	/*
	background: linear-gradient(to bottom, rgba(240, 249, 255, 1) 0%, rgb(191, 214, 228) 100%) !important;
	*/
	border: 1px solid #90b5d5;
}
.sc-sidebar-slim-button-container:hover {
	background-color: #e5e5e5;
}

.sc-sidebar-slim-button {
	display: contents;
	border: none;
	padding-top: 10px;
	padding-left: 8px;
	font-size: 9pt;
}

.sc-sidebar-slim-button-dot {
	height: 10px;
	width: 10px;
	background-color: rgb(120, 204, 50);
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	right: 5px;
}

.sc-tab-button-dot-tools {
	height: 10px;
	width: 10px;
	background-color: rgb(120, 204, 50);
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	top: 74px;
	right: 5px;
}

.sc-tab-button-dot-themes {
	height: 10px;
	width: 10px;
	background-color: rgb(120, 204, 50);
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	top: 202x;
	right: 5px;
}

.sc-sidebar-slim-footer-button {
	display: block;
	max-width: 62px;
	min-width: 62px;
	margin-bottom: 5px;
	font-size: 8pt;
	font-weight: bold;
	text-align: center;
	height: 20px;
	padding-left: 1px;
}

.sc-sidebar-slim-footer-button:hover {
	border: 1px solid #80b9ff;
	box-shadow: 0px 0px 5px #80b9ff;
}

.sc-sidebar-slim-footer-shortcuts {
	background-color: #3980cc;
	border-radius: 5px;
	color: white;
	height: 25px;
	min-width: 62px;
}

.sc-sidebar-slim-footer-container {
	position: absolute;
	bottom: 0;
	padding-bottom: 10px;
	padding-left: 2px;
	padding-right: 1px;
}

.sc-sidebar-slim-footer-tool {
	text-align: center;
	vertical-align: middle;
	margin-top: 5px;
}
.sc-sidebar-slim-footer-tool:hover {
	background-color: #9e9e9e;
}

.sc-sidebar-slim-footer-label {
	font-size: 9pt;
}

.sc-sidebar-slim-footer-img {
	display: block;
	margin: 0 auto;
}

@media only screen and (max-width: 770px) {
	.sc-sidebar-slim-container {
		display: none;
	}
}
